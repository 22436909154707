import { AnalyticsEvents, fireAnalyticsEvent, initAnalytics } from './analytics';
import { expiredToken, getErrorTemplate } from './common';
import { CallSurvey } from './surveys/CallSurvey';
import { isJwtValid, parseJwt } from './utils';
import './app.element.scss';
import { Ame } from './surveys/Ame';

export class AppElement extends HTMLElement {
  public static observedAttributes = [];

  connectedCallback() {
    // eslint-disable-next-line no-restricted-globals
    const token = location.pathname.split('/')[1];
    initAnalytics({ token });
    try {
      const isValid = isJwtValid(token);
      if (!isValid) {
        fireAnalyticsEvent(AnalyticsEvents.InvalidTokenOrExpired, { token });
        this.innerHTML = expiredToken;
        return;
      }
    } catch (e) {
      fireAnalyticsEvent(AnalyticsEvents.ErrorMalformedJWT);
      this.innerHTML = getErrorTemplate('malformed path');
      return;
    }
    const parsed = parseJwt(token);
    fireAnalyticsEvent(AnalyticsEvents.Entered, { type: parsed.questionnaire });
    switch (parsed.questionnaire) {
      case 'ame': {
        new Ame(this, token);
        return;
      }
      default: {
        new CallSurvey(this, token);
      }
    }
  }
}
customElements.define('laguna-root', AppElement);
