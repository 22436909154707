import { getFireAnalyticsEvent, initWebAnalytics, identify } from '@laguna/webAnalytics';

export enum AnalyticsEvents {
  Entered = 'Entered',
  SatisfactionPressed = 'SatisfactionPressed',
  NoteChanged = 'NoteChanged',
  Submit = 'Submit',
  ErrorMalformedJWT = 'ErrorMalformedJWT',
  InvalidTokenOrExpired = 'InvalidTokenOrExpired',
}

const analyticsEnabled = process.env.NX_REACT_APP_MIXPANEL_TOKEN && process.env.NX_REACT_APP_MIXPANEL_TOKEN !== 'false';

const fireAnalyticsEvent = getFireAnalyticsEvent<typeof AnalyticsEvents>();

const initAnalytics = async ({ token = '' }) => {
  if (!analyticsEnabled) return;
  await initWebAnalytics();
  identify({ id: token });
};

export { initAnalytics, fireAnalyticsEvent };
