import { logger } from '@laguna/logger';
import { getEndpointFromToken } from './utils';

export interface AppointmentSurveyPayload {
  note?: string;
  satisfaction: string;
  signature: string;
}
export interface AMESurveyPayload {
  answers: { code: string; value: string }[];
  signature: string;
}

export const submitAppointmentSurvey = async (body: AppointmentSurveyPayload, token: string) => {
  const endpoint = getEndpointFromToken(token);
  try {
    logger.info('submitting CSAT survey...', { data: body });
    await fetch(`${endpoint}/api/appointments/survey`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  } catch (error) {
    logger.error('Error submitting survey', { error });
  }
};

export const submitAmeSurvey = async (body: AMESurveyPayload, token: string) => {
  const endpoint = getEndpointFromToken(token);
  try {
    logger.info('submitting AME survey...', { data: body });
    await fetch(`${endpoint}/api/questionnaire/survey`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    });
  } catch (error) {
    logger.error('Error submitting survey', { error });
  }
};
