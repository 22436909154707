import { initTranslations } from '@laguna/translations';
// @ts-ignore ignore svg
import success from '../assets/success.svg';
// @ts-ignore ignore svg
import expired from '../assets/expired.svg';

export const i18n = initTranslations('survey');

export const mainWrapper = (content: string, additionalClasses = '') => `
<div id="main" class="${additionalClasses}">
  <div id="main-inner">
    ${content}
  </div>
</div>
`;

export const expiredToken = mainWrapper(`
  <img src="${expired}" />
  <h1>${i18n.t('expiredTitle')}</h1>
`);

export const successTemplate = mainWrapper(
  `
  <img src="${success}" />
  <h1>${i18n.t('successTitle')}</h1>
  <p>${i18n.t('successDescription')}</p>
`,
  'success'
);

export const getErrorTemplate = (reason = '') => `
<div id="main">
  <h1>${i18n.t('common:errors:generalError')}</h1>
  <p>${reason}</p>
</div>
`;
