import mixpanel from 'mixpanel-browser';
import { logger } from './logger';

const DEFAULT_MIXPANEL_TOKEN = process.env.NX_REACT_APP_MIXPANEL_TOKEN || '';
const analyticsEnabled = DEFAULT_MIXPANEL_TOKEN && DEFAULT_MIXPANEL_TOKEN !== 'false';

export const initWebAnalytics = async () => {
  if (!analyticsEnabled) return;
  await mixpanel.init(process.env.NX_REACT_APP_MIXPANEL_TOKEN || '');
};

export const identify = async ({ id = '', alias = '', orgId = '' }) => {
  if (!analyticsEnabled) return;
  mixpanel.identify(id);
  if (alias) {
    mixpanel.alias(alias, id);
  }
  if (orgId) {
    mixpanel.register({ orgId });
  }
};

export const getFireAnalyticsEvent =
  <T>() =>
  (key: T[keyof T], attr = {}, metrics = {}) => {
    if (!analyticsEnabled) return;
    try {
      mixpanel.track(key as string, { ...attr, ...metrics });
    } catch (error) {
      logger.warn('analytics error', { error });
    }
  };
