import { datadogLogs } from '@datadog/browser-logs';

export const init = () => {
  datadogLogs.init({
    env: process.env.NX_REACT_APP_ENV,
    service: 'web',
    clientToken: process.env.NX_REACT_APP_LOG_KEY || '',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
};

export const addLoggerContext = (context: { [key: string]: any }) => {
  datadogLogs.logger.addContext('__EVENT_CONTEXT__', context);
};

export const logger = datadogLogs.logger;

export default {
  init,
  addLoggerContext,
  logger,
};
