import { AnalyticsEvents, fireAnalyticsEvent } from '../analytics';
import { AppointmentSurveyPayload, submitAppointmentSurvey } from '../api';
import { getErrorTemplate, i18n, mainWrapper, successTemplate } from '../common';

const createSelectItem = (value: number) => `
<label class="selection-item-container">
  <input data-testid="radio_${value}" type="radio" name="satisfaction" value="${value}" />
  <span class="selection-item">${value}</span>
</label>
`;

export const callSurvey = mainWrapper(`
<form data-testid="call-survey-wrapper">
  <p>${i18n.t('satisfactionTitle')}</p>
  <div class="selection">
  ${[1, 2, 3, 4, 5].map(createSelectItem).join('\n')}
  </div>
  <div class="selection-labels">
    <div>${i18n.t('unsatisfied')}</div>
    <div>${i18n.t('satisfied')}</div>
  </div>
  <textarea data-testid="note" rows="4" cols="50" name="note" placeholder="${i18n.t('notePlaceHolder')}"></textarea>
  <button role="submit" class="disabled" type="submit">${i18n.t('common:send')}</button>
</form>
`);

const getSubmitInfo = (form: HTMLFormElement, signature: string): AppointmentSurveyPayload => {
  const data = new FormData(form);
  const obj: AppointmentSurveyPayload = { signature, satisfaction: '' };

  data.forEach((value, key) => {
    (obj as any)[key] = value;
  });
  return obj;
};

export class CallSurvey {
  element: HTMLElement;
  token: string;

  constructor(element: HTMLElement, token: string) {
    this.element = element;
    this.token = token;
    element.innerHTML = callSurvey;
    const form = element.querySelector('form');
    const textarea = element.querySelector('textarea');
    const radioInputs = element.querySelectorAll('input');
    const submitButton = element.querySelector('button');
    if (!form || !submitButton || !textarea) {
      element.innerHTML = getErrorTemplate();
      return;
    }

    radioInputs.forEach((radioInput) => {
      radioInput.onclick = () => {
        fireAnalyticsEvent(AnalyticsEvents.SatisfactionPressed, { value: radioInput.value });
        submitButton.classList.remove('disabled');
      };
    });

    let noteChanged = false;
    textarea.onchange = () => {
      if (noteChanged) {
        return;
      }
      noteChanged = true;
      fireAnalyticsEvent(AnalyticsEvents.NoteChanged);
    };

    form.addEventListener(
      'submit',
      async function (event) {
        event.preventDefault();
        submitButton.classList.add('disabled');
        const info = getSubmitInfo(form, token);
        fireAnalyticsEvent(AnalyticsEvents.Submit, { type: 'csat' });
        await submitAppointmentSurvey(info, token);
        element.innerHTML = successTemplate;
      },
      false
    );
  }
}
