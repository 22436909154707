type Segment = { type: 'URL' | 'text' | 'highlight'; value: string };
export const segmentString = (givenString: string): Segment[] => {
  // (https?:\/\/[^\s]+) - matches http:// or https:// followed by everything that isn't a space
  const regex = /(https?:\/\/[^\s]+)/g;
  const matches = givenString.match(regex);
  const segments: Segment[] = [];
  let currentPosition = 0;
  if (matches) {
    matches.forEach((match) => {
      const index = givenString.indexOf(match, currentPosition);
      if (index > currentPosition) {
        segments.push({
          type: 'text',
          value: givenString.substring(currentPosition, index),
        });
      }
      segments.push({
        type: 'URL',
        value: match,
      });
      currentPosition = index + match.length;
    });
    if (currentPosition < givenString.length) {
      segments.push({
        type: 'text',
        value: givenString.substring(currentPosition),
      });
    }
    // Return the array of segments
    return segments;
  } else {
    return [{ type: 'text', value: givenString }];
  }
};
// taken from https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export const parseJwtPart = (token: string, header = false) => {
  const base64Url = token.split('.')[header ? 0 : 1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const segmentWords = (value: string, words?: string[]): Segment[] => {
  const sections: Segment[] = [];
  if (!words || words.length <= 0) {
    sections.push({ type: 'text', value });
    return sections;
  }
  const regexPattern = `\\b(${words.join('|')})\\b`;
  const regex = new RegExp(regexPattern, 'gi');
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(value)) !== null) {
    const matchIndex = match.index;
    const matchedWord = match[0];

    if (matchIndex > lastIndex) {
      const text = value.substring(lastIndex, matchIndex);
      sections.push({ type: 'text', value: text });
    }
    sections.push({ value: matchedWord, type: 'highlight' });
    lastIndex = matchIndex + matchedWord.length;
  }

  if (lastIndex < value.length) {
    const remainingSection = value.substring(lastIndex);
    sections.push({ value: remainingSection, type: 'text' });
  }
  return sections;
};

export const isJwtExpired = (token: string) => {
  const { exp: expiryInSeconds } = parseJwtPart(token);
  if (!expiryInSeconds) return true;
  return Date.now() > expiryInSeconds * 1000;
};

export const bumpVersion = (version: string, type: 'patch' | 'minor' | 'major') => {
  const parts = version.split('.');
  const index = ['major', 'minor', 'patch'].indexOf(type);
  if (index < 0) throw new Error('Invalid version type');
  if (parts.length !== 3) throw new Error('Invalid version format');
  parts[index] = (parseInt(parts[index]) + 1).toString();
  // when bumping a version, reset all the parts after the bumped part to 0
  for (let i = index + 1; i < parts.length; i++) {
    parts[i] = '0';
  }

  return parts.join('.');
};
