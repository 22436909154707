import { default as i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './resources/en.json';

export const EN_TRANSLATION = en;
// register is nested under app in translation

type TranslationFileStructure = typeof en;
type AvailableApps = keyof TranslationFileStructure;

/**
 * - create namespace for each nested object in selected app translation
 * - Set all root keys under "translation" for default namespaceless access (t('myKey'))
 * { a:"a",c:"c",b:{keys...}} => {translation:{ a:"a",c:"c"},b:{keys...}}
 */
const getRootLevelKeys = (obj: any) => {
  const { translation, namespaces } = Object.keys(obj).reduce<{
    translation: any;
    namespaces: any;
  }>(
    (acc, current) => {
      if (typeof obj[current] === 'string') {
        acc.translation[current] = obj[current];
      } else {
        acc.namespaces[current] = obj[current];
      }
      return acc;
    },
    { translation: {}, namespaces: {} }
  );

  return {
    ...namespaces,
    translation,
  };
};

const getComposedJSON = (translation: TranslationFileStructure, appName: AvailableApps) => ({
  ...getRootLevelKeys(translation[appName]),
  common: {
    ...translation.common,
    // @ts-ignore common isn't present in all trans domains
    ...(translation[appName].common || {}),
  },
});

export const initTranslations = (app: AvailableApps) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: getComposedJSON(en, app),
      },
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
  return i18n;
};
// this is a comment to re run a build
export const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
};
