import { logger } from '@laguna/logger';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { AnalyticsEvents, fireAnalyticsEvent } from '../analytics';
import { AMESurveyPayload, submitAmeSurvey } from '../api';
import { successTemplate, mainWrapper } from '../common';
import { ameItems, QuestionType } from './questionnaires';
import './questionnaireStyle.scss';

const questionWrapper = (question: QuestionType, content: string) =>
  `<div id="${question.code}"><h4>${question.label}</h4>${content}</div>`;

const renderContent = (question: QuestionType) => {
  switch (question.type) {
    case 'label':
      return questionWrapper(question, '');
    case 'range': {
      const { min, max } = question.range;
      const optionsArr = new Array(max.value + 1 - min.value).fill(0).map((_, i) => i + min.value);
      return questionWrapper(
        question,
        `
        <div class="range-question">
        ${optionsArr
          .map(
            (option) =>
              `<div class="option" data-testid="radio_${question.code + option}"  id=${question.code + option}  name="${
                question.code
              }" value="${option}" >${option}</div>`
          )
          .join('\n')}
        </div>
      `
      );
    }
    case 'choice': {
      return questionWrapper(
        question,
        `<div class="choice-question">
      ${question.options
        .map(
          (option) =>
            `<div class="option" data-testid="answer_${question.code + option.value}"  id=${
              question.code + option
            }  name="${question.code}" value="${option.value}" >${option.label}</div>`
        )
        .join('\n')}
    </div>`
      );
    }
    case 'text': {
      return questionWrapper(
        question,
        `<div class="text-question">
          <textarea name="${question.code}" class="answer-input" data-testid="text-input" ></textarea>
        </div>
        `
      );
    }
  }
};

const ameWrapper = mainWrapper(`
<div data-testid="ame-wrapper" class="questionnaire-wrapper">
<div class="questions-area"> ${ameItems
  .map(
    (question) => `
  <div class="question" data-testid="form-question-${question.code}">
  ${renderContent(question)}
  </div>`
  )
  .join('\n')}
</div>
<div class="actions-area">
  <button id="back" class="secondary" data-testid="back-button">Back</button>
  <button id="next" data-testid="next-button">Next</button>
</div>
</div>
`);

const CLASSES = {
  active: 'active',
  before: 'before',
  after: 'after',
  question: 'question',
  selected: 'selected',
};

export class Ame {
  element: HTMLElement;
  nextButton: HTMLButtonElement;
  backButton: HTMLButtonElement;
  token: string;
  currentQuestionIndex = 0;
  formState: Record<string, string> = {};
  constructor(element: HTMLElement, token: string) {
    this.element = element;
    this.token = token;
    this.element.innerHTML = ameWrapper;
    const nextButton = document.getElementById('next');
    const backButton = document.getElementById('back');
    if (!nextButton || !backButton) {
      throw new Error('error');
    }
    this.nextButton = nextButton as HTMLButtonElement;
    this.backButton = backButton as HTMLButtonElement;

    this.addListenersToOptions();
    this.setActive(0);
  }
  getNextButtonLabel = (isFirst: boolean, isLast: boolean) => {
    if (isFirst) return i18next.t('common:start');
    if (isLast) return i18next.t('common:submit');
    return i18next.t('common:next');
  };

  updateButtonState = () => {
    const isFirst = this.currentQuestionIndex === 0;
    const isLast = this.currentQuestionIndex === ameItems.length - 1;
    this.nextButton.textContent = this.getNextButtonLabel(isFirst, isLast);
    this.nextButton.disabled = isLast && isEmpty(this.formState);
    this.backButton.style.display = isFirst ? 'none' : 'block';
  };
  getClassElementsArray = (className: string, element = this.element) => {
    const optionCollection = element.getElementsByClassName(className);
    return [].slice.call(optionCollection) as HTMLDivElement[];
  };

  addListenersToOptions = () => {
    const options = this.getClassElementsArray('option');
    const inputs = this.getClassElementsArray('answer-input');

    this.nextButton.addEventListener('click', this.onNextClick);
    this.backButton.addEventListener('click', this.onBackClick);
    options.forEach((option) => option.addEventListener('click', this.onOptionClick));
    inputs.forEach((input) => input.addEventListener('keyup', this.onAnswerInputChange));
  };
  onAnswerInputChange = (e: Event) => {
    const target = e.target as HTMLTextAreaElement;
    const name = (target as HTMLTextAreaElement).getAttribute('name');
    if (name) {
      this.formState[name] = target.value;
      this.updateButtonState();
    }
  };

  onOptionClick = (e: MouseEvent) => {
    const value = (e.target as HTMLElement).getAttribute('value');
    const name = (e.target as HTMLElement).getAttribute('name');
    if (name && value) {
      this.formState[name] = value;
      this.updateOptionsFor(name, value);
    }
    this.updateButtonState();
  };
  updateOptionsFor = (name: string, value: string) => {
    const questionElement = document.getElementById(name);
    if (!questionElement) {
      logger.error('missing question', { name });
      return;
    }
    const options = this.getClassElementsArray('option', questionElement);
    options.forEach((option) => {
      const isSelected = option.getAttribute('value') === value;
      option.classList.remove(CLASSES.selected);
      if (isSelected) {
        option.classList.add(CLASSES.selected);
      }
    });
  };

  onNextClick = () => {
    const isLast = this.currentQuestionIndex === ameItems.length - 1;
    if (isLast) {
      this.submitResults();
    } else {
      this.setActive(this.currentQuestionIndex + 1);
    }
  };
  onBackClick = () => {
    if (this.currentQuestionIndex > 0) {
      this.setActive(this.currentQuestionIndex - 1);
    }
  };

  async submitResults() {
    fireAnalyticsEvent(AnalyticsEvents.Submit, { type: 'ame' });
    const surveyPayload: AMESurveyPayload = {
      signature: this.token,
      answers: Object.entries(this.formState).map(([code, value]) => ({ code, value })),
    };
    await submitAmeSurvey(surveyPayload, this.token);
    this.element.innerHTML = successTemplate;
  }

  setActive(activeIndex: number) {
    this.currentQuestionIndex = activeIndex;
    const elements = this.getClassElementsArray('question');
    elements.forEach((element, index) => {
      element.className = CLASSES.question;
      if (index === activeIndex) {
        element.classList.add(CLASSES.active);
      } else {
        element.classList.add(index > activeIndex ? CLASSES.before : CLASSES.after);
      }
    });
    this.updateButtonState();
  }
}
