interface Option {
  value: number;
  label: string;
}
interface BaseQuestion {
  order: number;
  code: string;
  type: string;
  label: string;
  required?: boolean;
  variant?: any;
  options?: any;

  items?: null;
}

interface LabelQuestionType extends BaseQuestion {
  type: 'label';
}

interface RangeQuestionType extends BaseQuestion {
  type: 'range';
  range: {
    max: Option;
    min: Option;
  };
}

interface OptionsQuestionType extends BaseQuestion {
  type: 'choice';
  options: Option[];
}

interface TextQuestionType extends BaseQuestion {
  type: 'text';
}

export type QuestionType = RangeQuestionType | OptionsQuestionType | LabelQuestionType | TextQuestionType;
export const ameItems: QuestionType[] = [
  {
    order: 0,
    code: 'q0',
    label:
      'This brief 5 question survey will help us to evaluate the effectiveness of the 30-day recovery program you participated in. Your responses will be confidential and will not impact your benefits, care or services.',
    type: 'label',
    required: false,
  },
  {
    order: 1,
    code: 'q1',
    label: 'How likely is it that you would recommend Laguna to a friend or colleague?',
    type: 'range',
    required: false,
    range: {
      max: {
        value: 10,
        label: 'extremely likely',
      },
      min: {
        value: 0,
        label: 'not likely',
      },
    },
  },
  {
    order: 2,
    code: 'q2',
    label: 'Please rate your overall level of satisfaction with Laguna.',
    type: 'choice',
    required: false,
    options: [
      {
        value: 1,
        label: 'Very dissatisfied',
      },
      {
        value: 2,
        label: 'Dissatisfied',
      },
      {
        value: 3,
        label: 'Neutral',
      },
      {
        value: 4,
        label: 'Satisfied',
      },
      {
        value: 5,
        label: 'Very satisfied',
      },
    ],
  },
  {
    order: 3,
    code: 'q3',
    label: 'I think Laguna improved the quality of my post-hospitalization recovery experience.',
    type: 'choice',
    required: false,
    options: [
      {
        value: 1,
        label: 'Strongly disagree',
      },
      {
        value: 2,
        label: 'Disagree',
      },
      {
        value: 3,
        label: 'Neither disagree nor agree',
      },
      {
        value: 4,
        label: 'Agree',
      },
      {
        value: 5,
        label: 'Strongly agree',
      },
    ],
  },
  {
    order: 4,
    code: 'q4',
    label:
      'Including Laguna in my benefits package would increase my confidence that my health plan is looking out for me and my family’s health.',
    type: 'choice',
    required: false,
    options: [
      {
        value: 1,
        label: 'Strongly disagree',
      },
      {
        value: 2,
        label: 'Disagree',
      },
      {
        value: 3,
        label: 'Neither disagree nor agree',
      },
      {
        value: 4,
        label: 'Agree',
      },
      {
        value: 5,
        label: 'Strongly agree',
      },
    ],
  },
  {
    order: 5,
    code: 'q5',
    label: 'Do you agree or disagree that Laguna created a user-friendly recovery experience?',
    type: 'choice',
    required: false,
    options: [
      {
        value: 1,
        label: 'Strongly disagree',
      },
      {
        value: 2,
        label: 'Disagree',
      },
      {
        value: 3,
        label: 'Neither disagree nor agree',
      },
      {
        value: 4,
        label: 'Agree',
      },
      {
        value: 5,
        label: 'Strongly agree',
      },
    ],
  },
  {
    order: 6,
    code: 'q6',
    label: 'Do you have any additional feedback that you would like to share on the Laguna 30-day recovery program?',
    type: 'text',
    required: false,
  },
];
