import { parseJwtPart, isJwtExpired } from '@laguna/common/utils/parsers';

export const parseJwt = (token: string) => parseJwtPart(token);

const tokenProps = ['appointmentId', 'recordingId', 'apiUrl', 'iat', 'exp'];
const ameTokenProps = ['memberId', 'apiUrl', 'iat', 'exp'];

export const isJwtValid = (token: string): any => {
  const parsedToken = parseJwtPart(token);
  const hasAllProps =
    tokenProps.every((prop) => !!parsedToken[prop]) || ameTokenProps.every((prop) => !!parsedToken[prop]);
  return hasAllProps && !isJwtExpired(token);
};

export const getEndpointFromToken = (token: string): any => {
  const { apiUrl } = parseJwt(token);
  if (!apiUrl) return false;
  return apiUrl as string;
};
